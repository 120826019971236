<template>
  <div class="component-profile flex-wrap">
    <div v-if="dataUser" class="container-info-perfil d-block col-12 col-md-8">
      <div class="col-12 col-lg-7 col-xl-6 mb-4 mx-auto px-md-3">
        Por favor ingresa la contraseña actual
      </div>
      <div class="col-12 col-lg-7 col-xl-6 mb-4 mx-auto px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validPassword ? 'invalid' : ''"
            class="label-input"
            :style="password ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Contraseña</label
          >
          <input
            v-model="password"
            :class="validPassword ? 'invalid-error' : ''"
            placeholder="Contraseña"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="family-name"
            @blur="validateBlur('validPassword')"
            @focus="validPassword = false"
          />
          <span v-if="validPassword" class="inavalida-input">{{
            password ? 'Apellido invalido' : 'Campo requerido'
          }}</span>
          <svg
            class="show-password"
            xmlns="http://www.w3.org/2000/svg"
            width="24.207"
            height="23.414"
            viewBox="0 0 24.207 23.414"
            @click="showPassword = !showPassword"
          >
            <g
              id="Grupo_5755"
              data-name="Grupo 5755"
              transform="translate(-407 -937.793)"
            >
              <g
                id="Grupo_5027"
                data-name="Grupo 5027"
                transform="translate(-1080.5 601.643)"
              >
                <path
                  id="Trazado_2344"
                  data-name="Trazado 2344"
                  d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                  transform="translate(0)"
                  fill="none"
                  stroke="#949494"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <circle
                  id="Elipse_582"
                  data-name="Elipse 582"
                  cx="4.304"
                  cy="4.304"
                  r="4.304"
                  transform="translate(1495.196 343.37)"
                  fill="none"
                  stroke="#949494"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
              <line
                v-if="!showPassword"
                id="Línea_1365"
                data-name="Línea 1365"
                y1="22"
                x2="22"
                transform="translate(408.5 938.5)"
                fill="none"
                stroke="#949494"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="col-12 col-lg-7 col-xl-6 mb-4 mx-auto">
        <hr />
        <p class="px-md-3">ingresa nueva contraseña</p>
      </div>
      <div class="col-12 col-lg-7 col-xl-6 mb-4 mx-auto px-md-3">
        <div class="input-simple-clover">
          <label
            :class="validConfirmPassword ? 'invalid' : ''"
            class="label-input"
            :style="newPassword ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Nueva contraseña</label
          >
          <input
            v-model="newPassword"
            :class="validConfirmPassword ? 'invalid-error' : ''"
            placeholder="Nueva contraseña"
            :type="showPasswordConfirm ? 'text' : 'password'"
            @blur="validateBlur('validConfirmPassword')"
            @focus="validConfirmPassword = false"
          />
          <span v-if="validConfirmPassword" class="inavalida-input">{{
            newPassword
              ? newPassword != password
                ? 'Mínimo 8 caracteres'
                : 'Las contraseñas no deben coincidir'
              : 'Campo requerido'
          }}</span>
          <svg
            class="show-password"
            xmlns="http://www.w3.org/2000/svg"
            width="24.207"
            height="23.414"
            viewBox="0 0 24.207 23.414"
            @click="showPasswordConfirm = !showPasswordConfirm"
          >
            <g
              id="Grupo_5755"
              data-name="Grupo 5755"
              transform="translate(-407 -937.793)"
            >
              <g
                id="Grupo_5027"
                data-name="Grupo 5027"
                transform="translate(-1080.5 601.643)"
              >
                <path
                  id="Trazado_2344"
                  data-name="Trazado 2344"
                  d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                  transform="translate(0)"
                  fill="none"
                  stroke="#949494"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <circle
                  id="Elipse_582"
                  data-name="Elipse 582"
                  cx="4.304"
                  cy="4.304"
                  r="4.304"
                  transform="translate(1495.196 343.37)"
                  fill="none"
                  stroke="#949494"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
              <line
                v-if="!showPasswordConfirm"
                id="Línea_1365"
                data-name="Línea 1365"
                y1="22"
                x2="22"
                transform="translate(408.5 938.5)"
                fill="none"
                stroke="#949494"
                stroke-width="2"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="col-12 col-lg-7 col-xl-6 mb-4 mx-auto px-md-3">
        <p class="text-center mb-5">
          Recuerda que la contraseña debe tener al menos 8 caracteres
        </p>
        <button class="bnt-primary w-100" @click="updatePasswordUser()">
          Guardar
        </button>
      </div>
      <span v-if="errorUpdatePassword" class="text-error">{{
        msgErrorupdatePassword
      }}</span>
    </div>
    <div v-else class="text-center">
      <h3>Aún no has iniciado sesión</h3>
      <p>Para poder acceder a este modulo primero debes iniciar sesión.</p>
      <button class="bnt-primary" @click="$bvModal.show('modal-login')">
        Iniciar sesión
      </button>
    </div>
    <img
      v-if="dataUser"
      src="@/assets/imgs/img-login-portada.png"
      class="col-12 col-md-4 ps-md-3 mb-auto"
      alt=""
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      password: '',
      validPassword: false,
      newPassword: '',
      validConfirmPassword: false,
      showPassword: false,
      showPasswordConfirm: false,
    }
  },
  computed: {
    ...mapState('user', [
      'msgErrorupdatePassword',
      'errorUpdatePassword',
      'dataUser',
    ]),
  },
  methods: {
    ...mapActions('user', ['updatePassword']),
    validateBlur(input) {
      if (input == 'validPassword') {
        this.validPassword = !(this.password.length > 3)
      } else if (input == 'validConfirmPassword') {
        this.validConfirmPassword = !(
          this.password.length > 3 && this.password != this.newPassword
        )
      }
    },
    async updatePasswordUser() {
      if (
        this.password.length > 3 &&
        this.password != this.newPassword &&
        this.password.length > 3
      ) {
        await this.updatePassword({
          password: this.password,
          newPassword: this.newPassword,
        })
      } else {
        this.validPassword = !(this.password.length > 3)
        this.validConfirmPassword = !(
          this.password.length > 3 && this.password != this.newPassword
        )
      }
    },
  },
}
</script>
